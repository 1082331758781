<!-- src/App.vue -->

<template>
  <div id="app">
    <nav class="flex items-center justify-between flex-wrap bg-blue-500 p-6">
      <div class="flex items-center flex-shrink-0 text-white mr-6">
        <img
          src="https://www.ikimura.co.id/wp-content/uploads/2018/01/Ikimura-2-e1510646594130-1.png"
          alt="Logo"
          class="h-15 w-30 mr-2 shadow-glow transition duration-300 ease-in-out hover:shadow-glow-hover"
        />
      </div>
    </nav>

    <!-- Router view to dynamically render components based on the route -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style scoped>
  /* Glowing effect */
  .shadow-glow {
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.8)); /* Adjust the values as needed */
  }

  /* Glowing effect on hover */
  .hover\:shadow-glow-hover:hover {
    filter: drop-shadow(0 0 20px rgba(255, 255, 255, 1));
  }
</style>
